import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Consumer } from './StateContext';
import AlertError from './AlertError';
import TranslationsContext from '../../Container/Language/TranslationsContext';

const DataProvider = ({
  children,
  sendRequest,
  // abortRequest,
  Provider,
  route: initialRoute,
  form: initialForm,
  value,
  data: dataProps,
  onDataWorking,
}) => {
  let t = null;
  try {
    const context = useContext(TranslationsContext);
    t = context.t;
  } catch (e) {
    //
  }

  let route = initialRoute;
  const mountedRef = useRef(true);

  const [form, setForm] = useState(initialForm);
  const [data, setData] = useState({
    dataLoading: false,
    dataErrors: null,
    data: dataProps || null,
  });

  const getData = useCallback(() => {
    if (form) {
      setData((d) => ({
        dataLoading: true,
        dataErrors: null,
        data: d.data,
      }));

      if (route === 'managerUsers' && form && form.invoice && form.invoice.trim()) { // костыль
        // eslint-disable-next-line react-hooks/exhaustive-deps
        route = 'managerUsersByInvoice';
      }

      return sendRequest(route, {
        body: route === 'managerUsersByInvoice' ? { invoice: form.invoice } : form,
      }).then((response) => {
        if (mountedRef.current) {
          if (response.errors && Object.keys(response.errors).length) {
            setData({
              dataLoading: false,
              dataErrors: response.errors,
              data: null,
            });
          } else {
            setData({
              dataLoading: false,
              dataErrors: null,
              data: typeof onDataWorking === 'function' ? onDataWorking(response.data, form) : response.data,
            });
          }
        }
      });
    }

    return null;
  }, [route, form, sendRequest]);

  // useEffect(() => {
  //   if (dataProps && !data.dataLoading && mountedRef.current
  //   && (!data || Number(data.id) !== Number(dataProps.id))) {
  //     setData({
  //       dataLoading: false,
  //       dataErrors: false,
  //       data: dataProps,
  //     });
  //   }
  // }, [dataProps]); if data props changed

  useEffect(() => {
    if (!mountedRef.current && !data.data) {
      getData();
    }
  }, [data, getData]); // first load

  useEffect(() => {
    if (mountedRef.current && route !== 'user') {
      getData();
    }
  }, [getData, route, form]); // if form changed

  useEffect(() => {
    if (!data.data && !data.dataLoading && dataProps) {
      setData({
        dataLoading: false,
        dataErrors: null,
        data: dataProps,
      });
    }
  }, [data, dataProps]);

  useEffect(() => () => {
    mountedRef.current = false;
  }, []);

  // useEffect(() => {
  //   return () => {
  //     abortRequest(route);
  //   }
  // }, [abortRequest, route]); kills synchronous requests

  return (
    <Provider
      value={{
        ...(_.mapValues(value, (v) => {
          if (typeof v === 'function') {
            return (...args) => v(...args, data.data);
          }
          return v;
        })),
        form,
        setForm,
        data: data.data,
        dataLoading: data.dataLoading,
        dataErrors: data.dataErrors,
        getData,
        sendRequest,
      }}
    >
      <AlertError error={data.dataErrors && data.dataErrors.access && t ? t('app:notify:operation:forbidden') : null} />

      {children}
    </Provider>
  );
};

DataProvider.propTypes = {
  children: PropTypes.node.isRequired,
  sendRequest: PropTypes.func.isRequired,
  // abortRequest: PropTypes.func.isRequired,
  Provider: PropTypes.instanceOf(Object).isRequired,
  route: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(Object),
  value: PropTypes.instanceOf(Object),
  data: PropTypes.instanceOf(Object),
};

DataProvider.defaultProps = {
  form: {},
  value: {},
  data: null,
};

const DataProviderContext = (props) => (
  <Consumer>
    {(stateState) => <DataProvider {...stateState} {...props} />}
  </Consumer>
);

export default DataProviderContext;
