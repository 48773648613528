import { useContext, useEffect } from 'react';

import AuthContext from '../../HO/Auth/AuthContext';
import ConfigsContext from '../../Container/Config/ConfigsContext';

const HelpcrunchChat = () => {
  const { user } = useContext(AuthContext);
  const { data } = useContext(ConfigsContext);

  useEffect(() => {
    let hS;
    if (user) {
      hS = user?.helpcrunchSettings;
    } else {
      hS = {
        organization: 'asms',
        appId: data.helpcrunchAppId,
      };
    }
    if (!hS || !hS.organization) {
      return;
    }
    window.helpcrunchSettings = hS;

    const widgetSrc = `https://${hS.organization}.widget.helpcrunch.com/`;
    window.HelpCrunch = (...args) => {
      window.HelpCrunch.q.push([...args]);
    };

    window.HelpCrunch.q = [];

    const r = () => {
      if (document.querySelector(`script[src="${widgetSrc}"`)) {
        return;
      }
      const s = document.createElement('script');
      s.async = 1;
      s.type = 'text/javascript';
      s.src = widgetSrc;
      (document.body || document.head).appendChild(s);
    };

    if (document.readyState === 'complete' || hS.loadImmediately) {
      r();
    } else if (window.attachEvent) {
      window.attachEvent('onload', r);
    } else {
      window.addEventListener('load', r, false);
    }
  }, [user, data]);

  return null;
};

export default HelpcrunchChat;
