import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import {
  Drawer,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import {
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';

import AuthContext from '../../../HO/Auth/AuthContext';
import { Consumer } from '../LayoutConfigContext';
import PermissionsContext from '../../../Container/Permission/PermissionsContext';
import SidebarManager from './SidebarManager';
import SidebarSupport from './SidebarSupport';
import SidebarSoftware from './SidebarSoftware';
import UserInfo from './UserInfo';
import SidebarUser from './SidebarUser';
import conf from '../../../../configs';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Sidebar = ({ open, setOpen }) => {
  const theme = useTheme();
  const isDownThanMD = useMediaQuery(theme.breakpoints.down('md'));
  const { pathname } = useLocation();
  let exp = 'panel1';
  if (pathname.substring(0, 14) === `${conf.prefix || ''}/manage/`) exp = 'panel2';
  if (pathname.substring(0, 15) === `${conf.prefix || ''}/support/`) exp = 'panel3';
  const [expanded, setExpanded] = useState(exp);
  const { can } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (isDownThanMD) {
      setOpen(false);
    }
  }, [pathname, isDownThanMD, setOpen]);

  return (
    <Consumer>
      {({ configs, colors }) => (
        <Drawer
          sx={{
            width: configs.sidebarWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: configs.sidebarWidth,
              boxSizing: 'border-box',
              pr: 1,
              border: 'none',
              backgroundColor: colors.backgroundColor,
            },
            backgroundColor: colors.backgroundColor,
          }}
          variant={isDownThanMD ? 'temporary' : 'persistent'}
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
        >
          <DrawerHeader>
            <UserInfo />
            <IconButton onClick={() => setOpen(false)}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>

          <SidebarUser expanded={expanded} setExpanded={setExpanded} />

          {can('manager') && (
            <SidebarManager expanded={expanded} setExpanded={setExpanded} />
          )}
          {[1, 3, 4, 19, 31, 41, 51, 52, 0].indexOf(Number(user.site)) > -1 && (
            <SidebarSupport expanded={expanded} setExpanded={setExpanded} />
          )}
          {[52, 54, 55].indexOf(Number(user.site)) === -1 && Number(user.site) < 59 && (
            <SidebarSoftware expanded={expanded} setExpanded={setExpanded} />
          )}
        </Drawer>
      )}
    </Consumer>
  );
};

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default Sidebar;
