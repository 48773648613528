import React from 'react';

import { styled } from '@mui/material/styles';
import { AccordionSummary as MuiAccordionSummary } from '@mui/material';

import { Consumer } from '../LayoutConfigContext';

import bgmenu from '../../../../assets/img/icons/bgmenu.png';

const AccordionSummary = styled(MuiAccordionSummary)(({ colors }) => ({
  fontSize: 18,
  borderLeft: '7px solid #f93',
  height: '42px',
  minHeight: 'auto',
  '& .MuiAccordionSummary-content': {
    margin: 0,
  },
  '&.Mui-expanded': {
    minHeight: 'auto',
    backgroundColor: '#fff',
    backgroundImage: colors.backgroundColor === '#ffffff' ? 'none' : `url(${bgmenu})`,
    backgroundPosition: 'right bottom',
    backgroundRepeat: 'no-repeat',
  },
}));

const AccordionSummaryContext = (props) => (
  <Consumer>
    {({ colors }) => (<AccordionSummary {...props} colors={colors} />)}
  </Consumer>
);

export default AccordionSummaryContext;
