import React, { useEffect, useState } from 'react';

import { Alert, Snackbar } from '@mui/material';

const AlertError = ({ error: errorProp }) => {
  const [error, setError] = useState(null);

  const snackbarProps = {
    open: !!error,
    autoHideDuration: 6000,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    onClose: () => setError(null),
    ClickAwayListenerProps: {
      onClickAway: () => null,
    },
  };
  const alertProps = {
    severity: 'error',
    sx: {
      width: '100%',
    },
    onClose: () => setError(null),
  };

  useEffect(() => {
    if (errorProp) {
      setError(errorProp);
    }

    return () => {
      setError(null);
    };
  }, [errorProp]);

  return (
    <Snackbar {...snackbarProps}>
      <Alert {...alertProps}>{error}</Alert>
    </Snackbar>
  );
};

export default AlertError;
