import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
} from '@mui/material';

import TranslationsContext from '../../Container/Language/TranslationsContext';
import { FormConsumer, FormProvider } from '../../HO/Form';

const AltegioModal = ({
  formErrors,
  formData,
  sendForm,
  formLoading,
}) => {
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const { t } = useContext(TranslationsContext);

  const [open, setOpen] = useState(!!searchParams.get('salon_id'));
  const [active, setActive] = useState(false);

  const dialogProps = {
    open,
    onClose: () => setOpen(false),
    maxWidth: 'xs',
  };
  const btnProps = {
    variant: 'contained',
    fullWidth: true,
    disabled: formLoading,
    onClick: () => (active ? sendForm({
      app_id: searchParams.get('app_id'),
      altegio: searchParams.get('salon_id'),
    }) : setOpen(false)),
  };

  useEffect(() => {
    if (formErrors) {
      //
    } else if (formData) {
      window.location.assign(`https://app.alteg.io/appstore/${searchParams.get('salon_id')}/applications/${searchParams.get('app_id')}/info`);
    }
  }, [formErrors, formData, searchParams]);

  return (
    <Dialog {...dialogProps}>
      <DialogTitle>{t('frontend:popup:altegio:title')}</DialogTitle>
      <DialogContent>
        <FormGroup sx={{ mt: 2, pl: 2 }}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={active}
                onChange={(event) => setActive(event.target.checked)}
              />
            )}
            label={t('frontend:popup:altegio:agree')}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button {...btnProps}>{t('frontend:popup:altegio:submit')}</Button>
      </DialogActions>
    </Dialog>
  );
};

const AltegioModalContext = (props) => (
  <FormProvider formName="altegio">
    <FormConsumer>
      {(value) => (<AltegioModal {...props} {...value} />)}
    </FormConsumer>
  </FormProvider>
);

export default AltegioModalContext;
