import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { CircularProgress } from '@mui/material';

import AppLayout from '../../Smart/Layout';
import { ConfigsProvider } from '../../Container/Config';
import RoutedContent from '../../../routes';
import { StateConsumer } from '../State';
import { AuthConsumer } from '../Auth';
import { PermissionsProvider } from '../../Container/Permission';
import configs from '../../../configs';

const AppRouter = () => (
  <Router basename={configs.path || '/'}>
    <StateConsumer>
      {({ showLoading }) => (showLoading ? (
        <div style={{ margin: '25px auto', textAlign: 'center' }}>
          <CircularProgress />
        </div>
      ) : (
        <ConfigsProvider form={{}}>
          <AuthConsumer>
            {({ user }) => (user ? (
              <PermissionsProvider form={{}}>
                <AppLayout>
                  <RoutedContent />
                </AppLayout>
              </PermissionsProvider>
            ) : (
              <GoogleReCaptchaProvider reCaptchaKey={configs.recaptcha}>
                <AppLayout>
                  <RoutedContent />
                </AppLayout>
              </GoogleReCaptchaProvider>
            ))}
          </AuthConsumer>
        </ConfigsProvider>
      ))}
    </StateConsumer>
  </Router>
);

export default AppRouter;
