import React from 'react';

import { Box, CircularProgress } from '@mui/material';

const LoadingData = () => {
  const boxProps = {
    sx: {
      width: '100%',
      textAlign: 'center',
      pt: 2,
      pb: 2,
    },
  };

  return (
    <Box {...boxProps}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingData;
