import React from 'react';
import PropTypes from 'prop-types';

import DataProvider from '../../HO/State/DataProvider';
import { Provider } from './TemplateCategoriesContext';

const TemplateCategoriesProvider = ({ children, form }) => (
  <DataProvider route="templateCategories" Provider={Provider} form={form}>
    {children}
  </DataProvider>
);

TemplateCategoriesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.instanceOf(Object),
};

TemplateCategoriesProvider.defaultProps = {
  form: null,
};

export default TemplateCategoriesProvider;
