import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';

import TranslationsContext from '../../../Container/Language/TranslationsContext';
import ConfigsContext from '../../../Container/Config/ConfigsContext';
import Accordion from './Accordion';
import AccordionSummary from './AccordionSummary';
import configs from '../../../../configs';

const SidebarSupport = () => {
  const { t } = useContext(TranslationsContext);
  const { data } = useContext(ConfigsContext);
  const [expanded, setExpanded] = useState(true);
  const { pathname } = useLocation();

  return (
    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
      <AccordionSummary
        sx={{ color: '#f66', borderLeftColor: '#f66' }}
      >
        {t('widget:menu:clients:support')}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {data.main_phone ? (
            <ListItem disablePadding>
              <ListItemButton sx={{ p: 0 }} component="a" href={`tel:${data.main_phone.replace(/[^0-9+]/, '')}`}>
                <ListItemText
                  align="center"
                  primary={(
                    <>
                      <small>{data.main_phone.substring(0, data.main_phone.lastIndexOf(' '))}</small>
                      <b>{data.main_phone.substring(data.main_phone.lastIndexOf(' '))}</b>
                      <small style={{ color: 'transparent' }}>
                        (
                        {t('widget:help:fax')}
                        )
                      </small>
                    </>
                  )}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
          {data.fax_phone ? (
            <ListItem disablePadding>
              <ListItemButton sx={{ p: 0 }} component="a" href={`tel:${data.fax_phone.replace(/[^0-9+]/, '')}`}>
                <ListItemText
                  align="center"
                  primary={(
                    <>
                      <small style={!data.main_phone || data.fax_phone.substring(0, data.fax_phone.lastIndexOf(' ')) !== data.main_phone.substring(0, data.main_phone.lastIndexOf(' ')) ? null : { color: 'transparent' }}>
                        {data.fax_phone.substring(0, data.fax_phone.lastIndexOf(' '))}
                      </small>
                      <b>{data.fax_phone.substring(data.fax_phone.lastIndexOf(' '))}</b>
                      <small>
                        (
                        {t('widget:help:fax')}
                        )
                      </small>
                    </>
                  )}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
          {data.callback_phone ? (
            <ListItem disablePadding>
              <ListItemButton sx={{ p: 0 }} component="a" href={`tel:${data.callback_phone.replace(/[^0-9+]/, '')}`}>
                <ListItemText
                  align="center"
                  primary={(
                    <>
                      <small>{data.callback_phone.substring(0, data.callback_phone.lastIndexOf(' '))}</small>
                      <b>{data.callback_phone.substring(data.callback_phone.lastIndexOf(' '))}</b>
                    </>
                  )}
                />
              </ListItemButton>
            </ListItem>
          ) : null}
          {data.callback_email ? (
            <ListItem disablePadding>
              <ListItemButton sx={{ p: 0 }} component="a" href={`mailto:${data.callback_email}`}>
                <ListItemText align="center" secondary={data.callback_email} />
              </ListItemButton>
            </ListItem>
          ) : null}
          {data.callback_skype ? (
            <ListItem disablePadding>
              <ListItemButton sx={{ p: 0 }} component="a" href={`skype:${data.callback_skype}?chat`}>
                <ListItemText align="center" secondary={data.callback_skype} />
              </ListItemButton>
            </ListItem>
          ) : null}
          {data.show_support ? (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ bgcolor: 'primary' }}
                component={Link}
                to={`${configs.prefix || ''}/support/new/`}
                selected={pathname.slice(0, `${configs.prefix || ''}/support/new/`.length) === `${configs.prefix || ''}/support/new/`}
              >
                <ListItemText primary={t('widget:help:ask')} />
              </ListItemButton>
            </ListItem>
          ) : null}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarSupport;
