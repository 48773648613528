import React, { lazy, Suspense } from 'react';
import { useRoutes } from 'react-router-dom';

import LoggedRoute from './components/LoggedRoute';
import configs from '../configs';

// import {
//   Recover,
//   RecoverInit,
//   SignIn,
//   SignUp,
//
//   Addressbook,
//   Dashboard,
//   Dialogs,
//   Finance,
//   Incoming,
//   NotFound,
//   Oblenergo,
//   Report,
//   ScarpelAlphanames,
//   Settings,
//   Sms,
//   Stats,
//   SupportTickets,
//   SupportTicketForm,
//   Templates,
//   Tools,
//
//   ManagerAlphanames,
//   ManagerAnalysis,
//   ManagerFinance,
//   ManagerGates,
//   ManagerUser,
//   ManagerUsers,
//   ManagerMenu,
//   ManagerNews,
//   ManagerNewsletter,
//   ManagerPages,
//   ManagerRouter,
//   ManagerPhoneList,
//   ManagerSites,
//   ManagerSoftware,
//   ManagerStats,
//   ManagerTools,
//   ManagerTranslations,
// } from './Pages';
import LoadingData from '../components/Functional/Progress/LoadingData';

const Recover = lazy(() => import('./Pages/Recover/Recover'));
const RecoverInit = lazy(() => import('./Pages/Recover/RecoverInit'));
const SignIn = lazy(() => import('./Pages/SignIn'));
const SignUp = lazy(() => import('./Pages/SignUp'));

const Addressbook = lazy(() => import('./App/AddressBook/AddressBook'));
const Dashboard = lazy(() => import('./Pages/Dashboard'));
const Dialogs = lazy(() => import('./App/Dialogs/Dialogs'));
const Finance = lazy(() => import('./App/Finance/Finance'));
const Incoming = lazy(() => import('./App/Incoming/Incoming'));
const NotFound = lazy(() => import('./Pages/NotFound'));
const Oblenergo = lazy(() => import('./App/Oblenergo/Oblenergo'));
const Report = lazy(() => import('./App/Report/Report'));
const ScarpelAlphanames = lazy(() => import('./App/Alphaname/ScarpelAlphanames'));
const Settings = lazy(() => import('./App/Settings/Settings'));
const Sms = lazy(() => import('./App/Sms/Sms'));
const Stats = lazy(() => import('./App/Stats/Stats'));
const SupportTickets = lazy(() => import('./App/Support/SupportTickets'));
const SupportTicketForm = lazy(() => import('./App/Support/SupportTicketForm'));
const SwitchLanguage = lazy(() => import('./Pages/NotFound/SwitchLanguage'));
const Templates = lazy(() => import('./App/Template/Templates'));
const Tools = lazy(() => import('./App/Tools/Tools'));
const ManagerAlphanames = lazy(() => import('./App/manager/Alphaname/ManagerAlphanames'));
const ManagerAnalysis = lazy(() => import('./App/manager/Analysis/ManagerAnalysis'));
const ManagerFinance = lazy(() => import('./App/manager/Finance/ManagerFinance'));
const ManagerGates = lazy(() => import('./App/manager/Gate/ManagerGates'));
const ManagerUser = lazy(() => import('./App/manager/User/ManagerUser'));
const ManagerUsers = lazy(() => import('./App/manager/User/ManagerUsers'));
const ManagerMenu = lazy(() => import('./App/manager/Menu/ManagerMenu'));
const ManagerNews = lazy(() => import('./App/manager/News/ManagerNews'));
const ManagerNewsletter = lazy(() => import('./App/manager/News/ManagerNewsletter'));
const ManagerPages = lazy(() => import('./App/manager/Page/ManagerPages'));
const ManagerRouter = lazy(() => import('./App/manager/Router/ManagerRouter'));
const ManagerPhoneList = lazy(() => import('./App/manager/PhoneList/ManagerPhoneList'));
const ManagerSites = lazy(() => import('./App/manager/Site/ManagerSites'));
const ManagerSoftware = lazy(() => import('./App/manager/Software/ManagerSoftware'));
const ManagerStats = lazy(() => import('./App/manager/Stats/ManagerStats'));
const ManagerTools = lazy(() => import('./App/manager/Tools/ManagerTools'));
const ManagerTranslations = lazy(() => import('./App/manager/Translation/ManagerTranslations'));

const routes = [{
  path: `:lang${configs.prefix || ''}/*`,
  element: <SwitchLanguage />,
}, {
  path: `${configs.prefix || ''}/`,
  children: [{
    index: true,
    element: <LoggedRoute valid={<Dashboard />} invalid={`${configs.prefix}/sign-in/`} />,
  }, {
    path: 'recover',
    children: [{
      index: true,
      element: <LoggedRoute valid={`${configs.prefix}/`} invalid={<RecoverInit />} />,
    }, {
      path: ':phone',
      element: <LoggedRoute valid={`${configs.prefix}/`} invalid={<Recover />} />,
    }],
  }, {
    path: 'sign-in',
    children: [{
      index: true,
      element: <LoggedRoute valid={`${configs.prefix}/`} invalid={<SignIn />} />,
    }, {
      path: ':phone',
      element: <LoggedRoute valid={`${configs.prefix}/`} invalid={<SignIn />} />,
    }],
  }, {
    path: 'sign-up',
    element: <LoggedRoute valid={`${configs.prefix}/`} invalid={<SignUp />} />,
  }, {
    path: 'sms',
    children: [{
      index: true,
      element: <LoggedRoute valid={<Sms />} invalid={configs.url} />,
    }, {
      path: ':tab',
      element: <LoggedRoute valid={<Sms />} invalid={configs.url} />,
    }],
  }, {
    path: 'alphaname',
    element: (
      <LoggedRoute valid={<ScarpelAlphanames />} invalid={configs.url} />
    ),
  }, {
    path: 'report',
    children: [{
      index: true,
      element: <LoggedRoute valid={<Report mode="customer" />} invalid={configs.url} />,
    }, {
      path: ':tab',
      element: <LoggedRoute valid={<Report mode="customer" />} invalid={configs.url} />,
    }],
  }, {
    path: 'finance',
    children: [{
      index: true,
      element: <LoggedRoute valid={<Finance />} invalid={configs.url} />,
    }, {
      path: ':tab',
      element: <LoggedRoute valid={<Finance />} invalid={configs.url} />,
    }],
  }, {
    path: 'stats',
    children: [{
      index: true,
      element: <LoggedRoute valid={<Stats />} invalid={configs.url} />,
    }, {
      path: ':tab',
      element: <LoggedRoute valid={<Stats />} invalid={configs.url} />,
    }],
  }, {
    path: 'incoming',
    children: [{
      index: true,
      element: <LoggedRoute valid={<Incoming mode="customer" />} invalid={configs.url} />,
    }, {
      path: ':tab',
      element: <LoggedRoute valid={<Incoming mode="customer" />} invalid={configs.url} />,
    }],
  }, {
    path: 'addressbook',
    element: <LoggedRoute valid={<Addressbook />} invalid={configs.url} />,
  }, {
    path: 'tools',
    element: <LoggedRoute valid={<Tools />} invalid={configs.url} />,
  }, {
    path: 'oblenergo',
    element: <LoggedRoute valid={<Oblenergo />} invalid={configs.url} />,
  }, {
    path: 'settings',
    children: [{
      index: true,
      element: <LoggedRoute valid={<Settings />} invalid={configs.url} />,
    }, {
      path: ':tab',
      element: <LoggedRoute valid={<Settings />} invalid={configs.url} />,
    }],
  }, {
    path: 'dialogs',
    element: <LoggedRoute valid={<Dialogs />} invalid={configs.url} />,
  }, {
    path: 'support',
    children: [{
      index: true,
      element: <LoggedRoute valid={<SupportTickets mode="customer" />} invalid={configs.url} />,
    }, {
      path: 'new',
      element: <LoggedRoute valid={<SupportTicketForm mode="customer" />} invalid={configs.url} />,
    }, {
      path: 'ticket/:ticketId',
      element: <LoggedRoute valid={<SupportTicketForm mode="customer" />} invalid={configs.url} />,
    }],
  }, {
    path: 'manage',
    children: [{
      path: 'user',
      children: [{
        index: true,
        element: (
          <LoggedRoute valid={<ManagerUsers />} invalid={configs.url} />
        ),
      }, {
        path: 'user_id/:userId',
        children: [{
          index: true,
          element: <LoggedRoute valid={<ManagerUser />} invalid={configs.url} />,
        }, {
          path: ':tab',
          element: <LoggedRoute valid={<ManagerUser />} invalid={configs.url} />,
        }],
      }],
    }, {
      path: 'report',
      children: [{
        index: true,
        element: <LoggedRoute valid={<Report mode="manager" />} invalid={configs.url} />,
      }, {
        path: 'user/:userId',
        element: <LoggedRoute valid={<Report mode="manager" />} invalid={configs.url} />,
      }, {
        path: ':tab',
        element: <LoggedRoute valid={<Report mode="manager" />} invalid={configs.url} />,
      }],
    }, {
      path: 'router',
      element: <LoggedRoute valid={<ManagerRouter />} invalid={configs.url} />,
    }, {
      path: 'gate',
      element: <LoggedRoute valid={<ManagerGates />} invalid={configs.url} />,
    }, {
      path: 'incoming',
      children: [{
        index: true,
        element: <LoggedRoute valid={<Incoming mode="manager" />} invalid={configs.url} />,
      }, {
        path: ':tab',
        element: <LoggedRoute valid={<Incoming mode="manager" />} invalid={configs.url} />,
      }],
    }, {
    //   path: 'scarpel-alphaname',
    //   element: (
    //     <LoggedRoute
    //       valid={<ManagerScarpelAlphaname />} invalid={configs.url}
    //     />
    //   ),
    // }, {
      path: 'alphaname',
      element: (
        <LoggedRoute valid={<ManagerAlphanames />} invalid={configs.url} />
      ),
    }, {
      path: 'page',
      element: <LoggedRoute valid={<ManagerPages />} invalid={configs.url} />,
    }, {
      path: 'menu',
      element: <LoggedRoute valid={<ManagerMenu />} invalid={configs.url} />,
    }, {
      path: 'finance',
      children: [{
        index: true,
        element: (
          <LoggedRoute valid={<ManagerFinance />} invalid={configs.url} />
        ),
      }, {
        path: ':tab',
        element: (
          <LoggedRoute valid={<ManagerFinance />} invalid={configs.url} />
        ),
      }],
    }, {
      path: 'stats',
      children: [{
        index: true,
        element: <LoggedRoute valid={<ManagerStats />} invalid={configs.url} />,
      }, {
        path: ':tab',
        element: <LoggedRoute valid={<ManagerStats />} invalid={configs.url} />,
      }],
    }, {
      path: 'analysis',
      element: (
        <LoggedRoute valid={<ManagerAnalysis />} invalid={configs.url} />
      ),
    }, {
      path: 'tools',
      element: <LoggedRoute valid={<ManagerTools />} invalid={configs.url} />,
    }, {
      path: 'site',
      element: <LoggedRoute valid={<ManagerSites />} invalid={configs.url} />,
    }, {
      path: 'software',
      element: (
        <LoggedRoute valid={<ManagerSoftware />} invalid={configs.url} />
      ),
    }, {
      path: 'newsletter',
      element: (
        <LoggedRoute valid={<ManagerNewsletter />} invalid={configs.url} />
      ),
    }, {
      path: 'support',
      children: [{
        index: true,
        element: <LoggedRoute valid={<SupportTickets mode="manager" />} invalid={configs.url} />,
      }, {
        path: 'new',
        element: <LoggedRoute valid={<SupportTicketForm mode="manager" />} invalid={configs.url} />,
      }, {
        path: 'ticket/:ticketId',
        element: <LoggedRoute valid={<SupportTicketForm mode="manager" />} invalid={configs.url} />,
      }],
    }, {
      path: 'phonelist',
      element: (
        <LoggedRoute valid={<ManagerPhoneList />} invalid={configs.url} />
      ),
    }, {
      path: 'news',
      element: <LoggedRoute valid={<ManagerNews />} invalid={configs.url} />,
    }, {
      path: 'translations',
      element: (
        <LoggedRoute valid={<ManagerTranslations />} invalid={configs.url} />
      ),
    }, {
      path: 'templates',
      element: <LoggedRoute valid={<Templates mode="manager" />} invalid={configs.url} />,
    }],
  }, {
    path: '*',
    element: <NotFound />,
  }],
}];

const RoutedContent = () => useRoutes(configs.prefix ? [{
  path: '/',
  element: <LoggedRoute valid={`${configs.prefix || ''}/`} invalid={configs.url} />,
}, ...routes] : routes);

export default () => (
  <Suspense fallback={<LoadingData />}>
    <RoutedContent />
  </Suspense>
);
