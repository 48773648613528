import React, { useContext, useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import AuthContext from '../../HO/Auth/AuthContext';
import LayoutConfigContext from './LayoutConfigContext';
import TranslationsContext from '../../Container/Language/TranslationsContext';
import { FormConsumer, FormProvider } from '../../HO/Form';

import { ReactComponent as BG1 } from '../../../assets/img/dashboard/bg.svg';
import { ReactComponent as BG2 } from '../../../assets/img/dashboard/bg_2.svg';
import ic from '../../../assets/img/dashboard/ico_1.svg';

const FeedBack = ({
  formErrors,
  formData,
  formLoading,
  sendForm,
  clearData,
}) => {
  const { t } = useContext(TranslationsContext);
  const { user, getUser } = useContext(AuthContext);
  const { colors } = useContext(LayoutConfigContext);

  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(0);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  const getColor = (index) => {
    if (hover >= index) return 'primary';
    if (rating >= index) return 'success';
    if (formErrors && formErrors.rating && rating < 1) return 'error';

    return 'info';
  };

  const onClose = () => {
    setOpen(false);
    clearData();
    getUser();
  };

  const onCancel = () => {
    setOpen(false);
    clearData();
    sendForm({ rating: -1 });
  };

  useEffect(() => {
    if (user.request_feedback) {
      setOpen(true);
    }
  }, [user]);

  if (formData) {
    return (
      <Dialog
        onClose={onClose}
        open={open}
        fullWidth
        sx={{
          '& .MuiDialog-paper': { maxWidth: '350px', borderRadius: '11px' },
        }}
      >
        <DialogTitle
          sx={{
            background: `url('data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToStaticMarkup(<BG2 />).replace('#0099FF', colors.btnColor))}') no-repeat center 0px`,
            height: '106px',
            mb: 3,
          }}
        />

        <DialogContent>
          <Typography color="primary" component="p" variant="h5" align="center" sx={{ fontWeight: '700' }}>{t('widget:feedback:success')}</Typography>
          <Typography align="center" sx={{ fontSize: '13px' }}>{t('widget:feedback:subsuccess')}</Typography>
        </DialogContent>

        <DialogActions sx={{ pb: 4, justifyContent: 'center' }}>
          <Button
            size="large"
            variant="contained"
            disabled={formLoading}
            onClick={onClose}
            sx={{ borderRadius: '4px' }}
          >
            {t('widget:feedback:close')}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  return (
    <Dialog
      onClose={onCancel}
      open={open}
      fullWidth
      sx={{
        '& .MuiDialog-paper': { maxWidth: '422px', borderRadius: '11px' },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          background: `url('data:image/svg+xml;base64,${btoa(ReactDOMServer.renderToStaticMarkup(<BG1 />).replace('#0099FF', colors.btnColor))}') no-repeat center`,
          // height: '45px',
          p: '50px 20px',
          '&:after': {
            content: '""',
            background: `url(${ic}) no-repeat center`,
            width: '45px',
            // min-width: 45px;
            position: 'absolute',
            top: '120px',
            left: '27px',
            height: '45px',
          },
        }}
      >
        <Typography component="p" variant="h5" align="center" sx={{ color: 'white', fontWeight: '700' }}>{t('widget:feedback:title')}</Typography>
        <Typography align="center" sx={{ color: 'white', fontSize: '13px' }}>{t('widget:feedback:subtitle')}</Typography>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onCancel}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'white',
        }}
      >
        <Close sx={{ fontSize: '14px' }} />
      </IconButton>

      <DialogContent sx={{ mt: 2 }}>
        <Box onMouseOut={() => setHover(0)} sx={{ textAlign: 'center' }}>
          {_.map(_.range(1, 11), (index) => (
            <Button
              key={`feedback-star-btn-${index}`}
              color={getColor(index)}
              onMouseOver={() => setHover(index)}
              onClick={() => setRating(index)}
              sx={{
                mx: 0.3,
                '& span': { display: 'none' },
                minWidth: '32px',
                minHeight: '32px',
                borderRadius: '4px',
              }}
              variant={rating >= index ? 'contained' : 'outlined'}
              size="small"
            >
              {index > 9 ? (
                <>
                  <FontAwesomeIcon icon="fa-solid fa-1" />
                  <FontAwesomeIcon icon="fa-solid fa-0" />
                </>
              ) : (
                <FontAwesomeIcon icon={`fa-solid fa-${index}`} />
              )}
            </Button>
          ))}
        </Box>

        <Grid container justifyContent="space-between" direction="row">
          <Grid item><Typography variant="caption" color="#B4B4B4">{t('widget:feedback:not-recommend')}</Typography></Grid>
          <Grid item><Typography variant="caption" color="#B4B4B4">{t('widget:feedback:recommend')}</Typography></Grid>
        </Grid>

        {rating > 0 && rating < 5 && (
          <TextField
            sx={{ mt: 2 }}
            fullWidth
            multiline
            label={t('widget:feedback:comment')}
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            error={!!(formErrors && formErrors.comment)}
          />
        )}
      </DialogContent>

      <DialogActions sx={{ pb: 5, justifyContent: 'center' }}>
        <Button
          size="large"
          variant="outlined"
          disabled={formLoading}
          onClick={() => sendForm({ rating, comment })}
          sx={{ borderRadius: '4px' }}
        >
          {t('widget:feedback:send')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const FeedBackContext = (props) => (
  <FormProvider formName="feedback">
    <FormConsumer>
      {(value) => (<FeedBack {...props} {...value} />)}
    </FormConsumer>
  </FormProvider>
);

export default FeedBackContext;
