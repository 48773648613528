import React, { useContext } from 'react';
// import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import {
  AccordionDetails,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import TranslationsContext from '../../../Container/Language/TranslationsContext';
import AuthContext from '../../../HO/Auth/AuthContext';
import PermissionsContext from '../../../Container/Permission/PermissionsContext';
import ConfigsContext from '../../../Container/Config/ConfigsContext';
import Accordion from './Accordion';
import AccordionSummary from './AccordionSummary';
import conf from '../../../../configs';

import BookIcon from '../../../../assets/img/sidebar/book.svg';
import DialogsIcon from '../../../../assets/img/sidebar/dialogs.svg';
import DocIcon from '../../../../assets/img/sidebar/doc.svg';
import EmailIcon from '../../../../assets/img/sidebar/email.svg';
import FinanceIcon from '../../../../assets/img/sidebar/finance.svg';
import StatsIcon from '../../../../assets/img/sidebar/stats.svg';
import SmsIcon from '../../../../assets/img/sidebar/sms.svg';
import GearIcon from '../../../../assets/img/sidebar/gear.svg';
import WrenchIcon from '../../../../assets/img/sidebar/wrench.svg';
import HelpIcon from '../../../../assets/img/sidebar/help.svg';
import QuestIcon from '../../../../assets/img/sidebar/quest.svg';

const SidebarUser = ({ expanded, setExpanded }) => {
  const { pathname } = useLocation();
  const { t } = useContext(TranslationsContext);
  const { data: configs } = useContext(ConfigsContext);
  const { user } = useContext(AuthContext);
  const { can } = useContext(PermissionsContext);

  let helpLink;
  switch (Number(user.site)) {
    case 3:
      helpLink = 'https://docs.alphasms.ua/';
      break;
    case 57:
      helpLink = 'https://docs.alphasms.net/';
      break;
    case 54:
      helpLink = 'https://docs.interconnect.solutions/';
      break;
    default:
      helpLink = '/help/index.htm';
  }

  return (
    <Accordion expanded={expanded === 'panel1'} onChange={() => setExpanded('panel1')}>
      <AccordionSummary
        component={Link}
        to={`${conf.prefix || ''}/`}
        sx={{ color: '#09f', borderLeftColor: '#09f' }}
      >
        {t('widget:menu:sms')}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {Number(user.id) !== 60124 && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${conf.prefix || ''}/sms/`} selected={pathname.slice(0, `${conf.prefix || ''}/sms/`.length) === `${conf.prefix || ''}/sms/`}>
                <ListItemIcon><img src={EmailIcon} alt="email" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:sms:send')} />
              </ListItemButton>
            </ListItem>
          )}
          {Number(user.site) === 52 && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${conf.prefix || ''}/alphaname/`} selected={pathname.slice(0, `${conf.prefix || ''}/alphaname/`.length) === `${conf.prefix || ''}/alphaname/`}>
                <ListItemIcon><img src={BookIcon} alt="book" /></ListItemIcon>
                <ListItemText primary={t('app:controller:sms:alphanames')} />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`${conf.prefix || ''}/report/`} selected={pathname.slice(0, `${conf.prefix || ''}/report/`.length) === `${conf.prefix || ''}/report/`}>
              <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
              <ListItemText primary={t('widget:menu:sms:reports')} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`${conf.prefix || ''}/finance/`} selected={pathname.slice(0, `${conf.prefix || ''}/finance/`.length) === `${conf.prefix || ''}/finance/`}>
              <ListItemIcon><img src={FinanceIcon} alt="finance" /></ListItemIcon>
              <ListItemText primary={t('widget:menu:sms:finance')} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`${conf.prefix || ''}/stats/`} selected={pathname.slice(0, `${conf.prefix || ''}/stats/`.length) === `${conf.prefix || ''}/stats/`}>
              <ListItemIcon><img src={StatsIcon} alt="stats" /></ListItemIcon>
              <ListItemText primary={t('widget:menu:sms:stats')} />
            </ListItemButton>
          </ListItem>
          {[52, 54, 55, 59, 60, 61].indexOf(Number(user.site)) === -1 && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${conf.prefix || ''}/incoming/`} selected={pathname.slice(0, `${conf.prefix || ''}/incoming/`.length) === `${conf.prefix || ''}/incoming/`}>
                <ListItemIcon><img src={SmsIcon} alt="sms" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:sms:incoming')} />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`${conf.prefix || ''}/addressbook/`} selected={pathname.slice(0, `${conf.prefix || ''}/addressbook/`.length) === `${conf.prefix || ''}/addressbook/`}>
              <ListItemIcon><img src={BookIcon} alt="book" /></ListItemIcon>
              <ListItemText primary={t('widget:menu:sms:addressbook')} />
            </ListItemButton>
          </ListItem>
          {[35, 52, 54, 55, 59, 61].indexOf(Number(user.site)) === -1 && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${conf.prefix || ''}/tools/`} selected={pathname.slice(0, `${conf.prefix || ''}/tools/`.length) === `${conf.prefix || ''}/tools/`}>
                <ListItemIcon><img src={WrenchIcon} alt="wrench" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:utils')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('oblenergo') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${conf.prefix || ''}/oblenergo/`} selected={pathname.slice(0, `${conf.prefix || ''}/oblenergo/`.length) === `${conf.prefix || ''}/oblenergo/`}>
                <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:oblenergo')} />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`${conf.prefix || ''}/settings/`} selected={pathname.slice(0, `${conf.prefix || ''}/settings/`.length) === `${conf.prefix || ''}/settings/`}>
              <ListItemIcon><img src={GearIcon} alt="gear" /></ListItemIcon>
              <ListItemText primary={t('widget:menu:sms:settings')} />
            </ListItemButton>
          </ListItem>
          {configs && configs.show_support ? (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${conf.prefix || ''}/support/`} selected={pathname.slice(0, `${conf.prefix || ''}/support/`.length) === `${conf.prefix || ''}/support/`}>
                <ListItemIcon><img src={HelpIcon} alt="help" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:support')} />
              </ListItemButton>
            </ListItem>
          ) : null}
          {[51].indexOf(Number(user.site)) > -1 && (
            <ListItem disablePadding>
              <ListItemButton component="a" href="https://tracker.smsbat.com/track/help/smsbat.com/panel/?in_source=smsbat.com&in_medium=referal&in_campaign=panel-link">
                <ListItemIcon><img src={QuestIcon} alt="quest" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:sms:manual')} />
              </ListItemButton>
            </ListItem>
          )}
          {[51].indexOf(Number(user.site)) === -1
            && [52, 54, 55, 59, 60, 61].indexOf(Number(user.site)) === -1 && (
            <ListItem disablePadding>
              <ListItemButton component="a" to={helpLink} target="_blank">
                <ListItemIcon><img src={QuestIcon} alt="quest" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:sms:manual')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('dialog:viber') && user.gv && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${conf.prefix || ''}/dialogs/`} selected={pathname.slice(0, `${conf.prefix || ''}/dialogs/`.length) === `${conf.prefix || ''}/dialogs/`}>
                <ListItemIcon><img src={DialogsIcon} alt="dialogs" width={24} /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:dialogs')} />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarUser;
