import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import App from './components/HO/App/AppRouter';
import { StateProvider } from './components/HO/State';
import { AuthProvider } from './components/HO/Auth';
import reportWebVitals from './reportWebVitals';
import './fas';

Sentry.init({
  dsn: 'https://58ee0a6c16256b766c0197e4fd47b22c@o4506897863344128.ingest.us.sentry.io/4507128953896960',
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  // <React.StrictMode>
  <StateProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </StateProvider>,
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
