import React from 'react';
import PropTypes from 'prop-types';

import DataProvider from '../../HO/State/DataProvider';
import { Provider } from './PermissionsContext';
import { AuthConsumer } from '../../HO/Auth';

const PermissionsProvider = ({ children, form }) => (
  <AuthConsumer>
    {({ user }) => (
      <DataProvider
        route="permissions"
        Provider={Provider}
        form={form}
        value={{
          can: (permission, data) => {
            if (permission === 'sms:link-statistics' && [120118, 120362, 120673, 120385, 120588].indexOf(Number(user.id)) === -1 && [35].indexOf(Number(user.site)) === -1) return true;
            if (permission === 'manager:finance:gate' || permission === 'sms:template:category') return false; // disabled
            return !!(data && (data.role === 'admin' || data.permissions.indexOf(permission) > -1));
          },
          hasRole: (role, data) => !!(data && (data.role === role)),
        }}
      >
        {children}
      </DataProvider>
    )}
  </AuthConsumer>
);

PermissionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.instanceOf(Object),
};

PermissionsProvider.defaultProps = {
  form: null,
};

export default PermissionsProvider;
