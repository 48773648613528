import React from 'react';

import { StateConsumer } from '../State';
import { Consumer } from './AuthContext';
import AuthProvider from './AuthProvider';

const AuthProviderContext = (props) => (
  <StateConsumer>
    {(stateState) => (<AuthProvider {...stateState} {...props} />)}
  </StateConsumer>
);

export { AuthProviderContext as AuthProvider };
export { Consumer as AuthConsumer };
