import React from 'react';
import PropTypes from 'prop-types';

import DataProvider from '../../HO/State/DataProvider';
import { Provider } from './ViberTemplatesContext';

const ViberTemplatesProvider = ({ children, form }) => (
  <DataProvider route="viberTemplates" Provider={Provider} form={form}>
    {children}
  </DataProvider>
);

ViberTemplatesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.instanceOf(Object),
};

ViberTemplatesProvider.defaultProps = {
  form: null,
};

export default ViberTemplatesProvider;
