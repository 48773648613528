import React from 'react';

import FormProvider from './FormProvider';
import { StateConsumer } from '../State';

const FormProviderContext = (props) => (
  <StateConsumer>
    {(stateState) => <FormProvider {...stateState} {...props} />}
  </StateConsumer>
);

export { FormProviderContext as FormProvider };
export { Consumer as FormConsumer } from './FormContext';
