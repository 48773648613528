import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@mui/material/styles';

import { Consumer } from './LayoutConfigContext';

const Main = styled('main', {
  shouldForwardProp: (prop) => prop !== 'open',
})(
  ({ theme, open, configs }) => ({
    overflow: 'hidden',
    flexGrow: 1,
    padding: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: theme.breakpoints.down('md') ? 0 : `-${configs.sidebarWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({
  theme,
}) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const Body = ({ open, children, ...other }) => (
  <Consumer>
    {({ configs }) => (
      <Main configs={configs} open={open} {...other}>
        <DrawerHeader />
        {children}
      </Main>
    )}
  </Consumer>
);

Body.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default Body;
