import React from 'react';
import PropTypes from 'prop-types';

import DataProvider from '../../HO/State/DataProvider';
import { Provider } from './MenuTopContext';

const MenuTopProvider = ({ children, form }) => (
  <DataProvider route="menuTop" Provider={Provider} form={form}>
    {children}
  </DataProvider>
);

MenuTopProvider.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.instanceOf(Object),
};

MenuTopProvider.defaultProps = {
  form: null,
};

export default MenuTopProvider;
