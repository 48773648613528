import React from 'react';
import PropTypes from 'prop-types';

import DataProvider from '../../HO/State/DataProvider';
import { Provider } from './TemplatesOwnContext';

const TemplatesOwnProvider = ({ children, form }) => (
  <DataProvider route="templatesOwn" Provider={Provider} form={form}>
    {children}
  </DataProvider>
);

TemplatesOwnProvider.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.instanceOf(Object),
};

TemplatesOwnProvider.defaultProps = {
  form: null,
};

export default TemplatesOwnProvider;
