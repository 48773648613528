import React, { useContext, useState } from 'react';
import { v4 } from 'uuid';
import _ from 'lodash';

import { MoreVert } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { MenuTopConsumer, MenuTopProvider } from '../../../Container/Menu';
import TranslationsContext from '../../../Container/Language/TranslationsContext';

const TopMenu = ({
  data,
  dataLoading,
  setForm,
  form,
}) => {
  const theme = useTheme();
  const isDownThanLG = useMediaQuery(theme.breakpoints.down('lg'));
  const { form: { language } } = useContext(TranslationsContext);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const onClick = (event) => {
    if (!form) setForm({});
    setAnchorEl(event.currentTarget);
  };

  if (!data || data.length === 0) {
    return null;
  }

  if (isDownThanLG || data.length > 5) {
    return (
      <>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup="true"
          onClick={onClick}
        >
          {dataLoading ? (<CircularProgress size={24} />) : (<MoreVert />)}
        </IconButton>

        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open && Boolean(data)}
          onClose={() => setAnchorEl(null)}
        >
          {_.map(data || [], (menu) => (
            <MenuItem component="a" key={v4()} href={menu.url}>
              {menu[`name_${language}`]}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        textAlign: 'center',
        verticalAlign: 'top',
        mx: 1,
      }}
    >
      {_.map(data || [], (menu) => (
        <Typography component="a" key={v4()} href={menu.url} sx={{ mx: 1 }}>
          {menu[`name_${language}`]}
        </Typography>
      ))}
    </Box>
  );
};

const TopMenuContext = (props) => (
  <MenuTopProvider form={{}}>
    <MenuTopConsumer>
      {(state) => (<TopMenu {...props} {...state} />)}
    </MenuTopConsumer>
  </MenuTopProvider>
);

export default TopMenuContext;
