import React from 'react';
import PropTypes from 'prop-types';

import DataProvider from '../../HO/State/DataProvider';
import { Provider } from './LanguagesContext';

const LanguagesProvider = ({ children, form }) => (
  <DataProvider route="languages" Provider={Provider} form={form}>
    {children}
  </DataProvider>
);

LanguagesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.instanceOf(Object),
};

LanguagesProvider.defaultProps = {
  form: null,
};

export default LanguagesProvider;
