import React from 'react';

import { styled } from '@mui/material/styles';
import { Accordion as MuiAccordion } from '@mui/material';

import { Consumer } from '../LayoutConfigContext';

const Accordion = styled(MuiAccordion)(({ colors }) => ({
  margin: 0,
  boxShadow: 'none',
  '&.Mui-expanded': {
    margin: 0,
  },
  backgroundColor: colors.backgroundColor,
}));

const AccordionContext = (props) => (
  <Consumer>
    {({ colors }) => (<Accordion {...props} colors={colors} />)}
  </Consumer>
);

export default AccordionContext;
