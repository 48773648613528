import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { styled, useTheme } from '@mui/material/styles';
import {
  AppBar as MuiAppBar,
  Box,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';

import { Consumer } from '../LayoutConfigContext';
import AuthContext from '../../../HO/Auth/AuthContext';
import LogOut from './LogOut';
import TopMenu from './TopMenu';
import LangMenu from './LangMenu';
import UserInfo from '../Sidebar/UserInfo';
import conf from '../../../../configs';

import bgmenu from '../../../../assets/img/icons/bgmenu.png';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open, configs }) => {
  const isDownThanMD = useMediaQuery(theme.breakpoints.down('md'));

  return ({
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      ...(isDownThanMD ? {
        width: `calc(100% - ${configs.sidebarWidth}px)`,
      } : {
        width: '100%',
        zIndex: '1201',
      }),
      marginLeft: `${configs.sidebarWidth}px`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  });
});

const TopBar = ({ open, setOpen, ...other }) => {
  const { user } = useContext(AuthContext);
  const theme = useTheme();
  const isDownThanMD = useMediaQuery(theme.breakpoints.down('md'));
  const isDownThanLG = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <Consumer>
      {({ colors, configs }) => (
        <AppBar position="fixed" open={open} configs={configs} {...other} sx={{ boxShadow: 'none' }}>
          <Toolbar style={isDownThanMD ? { backgroundColor: '#fff' } : { paddingRight: 0 }}>
            {user ? (
              <IconButton
                aria-label="open drawer"
                onClick={() => setOpen(true)}
                edge="start"
                sx={{ mr: 2, ...(open && { display: 'none' }), my: isDownThanMD ? 1 : 0 }}
              >
                <MenuIcon />
              </IconButton>
            ) : null}
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1 }}
              style={{ textAlign: isDownThanMD ? 'center' : 'left' }}
            >
              <Link to="/" style={{ display: 'inline-block' }}>
                {configs.logo ? (
                  <img
                    alt="logo"
                    src={`${conf.url || ''}${configs.logo}`}
                    style={{
                      display: 'block', maxWidth: '100%', height: 'auto', maxHeight: configs.logoHeight,
                    }}
                  />
                ) : null}
              </Link>

              {isDownThanLG ? null : (<TopMenu />)}
            </Typography>

            <Box
              sx={isDownThanMD ? {
                whiteSpace: 'nowrap',
              } : {
                backgroundImage: colors.backgroundColor === '#ffffff' ? 'none' : `url(${bgmenu})`,
                bgcolor: 'white',
                backgroundRepeat: 'no-repeat',
                '& .user-info': { display: 'inline-block' },
                '& .user-info-name': { display: 'inline-block' },
                pl: 6,
                py: 0.5,
                pr: 3,
              }}
            >
              {isDownThanMD || !user ? null : (<UserInfo />)}
              <LangMenu />
              {isDownThanLG ? (<TopMenu />) : null}
              {user ? (<LogOut />) : null}
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </Consumer>
  );
};

TopBar.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default TopBar;
