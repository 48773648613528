const colors = {
  linkColor: '#0099ff',
  btnColor: '#0099ff',
  btnTextColor: '#ffffff',
  backgroundColor: '#ffffff',
};

const configs = {
  sidebarWidth: 240,
  logo: null,
  logoHeight: 36,
};

const muiConfigs = {
  typography: {
    allVariants: {
      color: '#666',
    },
  },
  palette: {
    primary: {
      main: colors.linkColor,
    },
    // secondary: {
    //   main: colors.secondaryMain,
    //   textColor: '#ffffff',
    // },
  },
  components: {
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) => theme.unstable_sx({
          width: 'calc(100% - 32px)',
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '& [autocomplete="one-time-code"]': {
            '--otp-digits': 6,
            '--otp-ls': '1.8ch',
            '--otp-gap': 3,

            '--_otp-bgsz': '100%', // 'calc(var(--otp-ls) + 2ch)',
            '--_otp-digit': 0,

            all: 'unset',
            background: 'linear-gradient(90deg var(--otp-bg, #BBB) calc(var(--otp-gap) * var(--otp-ls)), transparent 0), linear-gradient(90deg, var(--otp-bg, #EEE) calc(var(--otp-gap) * var(--otp-ls)),transparent 0)',
            backgroundPosition: 'calc(var(--_otp-digit) * var(--_otp-bgsz)) 0, 0 0',
            backgroundRepeat: 'no-repeat, repeat-x',
            backgroundSize: 'var(--_otp-bgsz) 100%',
            caretColor: 'var(--otp-cc, #222)',
            caretShape: 'block',
            clipPath: 'inset(0% calc(var(--otp-ls) / 2) 0% 0%)',
            fontFamily: 'ui-monospace, monospace',
            fontSize: 'var(--otp-fz, 1.4em)',
            inlineSize: 'calc(var(--otp-digits) * var(--_otp-bgsz))',
            letterSpacing: 'var(--otp-ls)',
            paddingBlock: 'var(--otp-pb, 1.2ch)',
            paddingInlineStart: 'calc(((var(--otp-ls) - 1ch) / 2) * var(--otp-gap))',
            // textAlign: 'center',
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: colors.backgroundColor,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // backgroundColor: colors.backgroundColor,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '& .MuiTableHead-root .MuiTableCell-root': {
            color: '#ffffff',
            backgroundColor: '#aaa',
            borderRight: '1px solid #eee',
          },
          '& .MuiTableHead-root .MuiTableCell-root:last-child': {
            borderRight: 'none',
          },
          '& .MuiTableBody-root .MuiTableCell-root': {
            color: '#666',
          },
          '& .MuiTableCell-root': {
            padding: '12px 16px',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          '&.MuiButton-contained': {
            color: colors.btnTextColor,
            backgroundColor: colors.btnColor,
          },
          fontSize: '0.8rem',
        },
      },
    },
  },
  shape: {
    borderRadius: 0,
  },
  // #666
};

export default muiConfigs;
export { colors, configs };
