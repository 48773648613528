import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import {
  AccordionDetails,
  Badge,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';

import { ViberTemplatesConsumer, ViberTemplatesProvider } from '../../../Container/Template';
import TranslationsContext from '../../../Container/Language/TranslationsContext';
import PermissionsContext from '../../../Container/Permission/PermissionsContext';
import AuthContext from '../../../HO/Auth/AuthContext';
import Accordion from './Accordion';
import AccordionSummary from './AccordionSummary';
import configs from '../../../../configs';

import DocIcon from '../../../../assets/img/sidebar/doc.svg';
import NewsIcon from '../../../../assets/img/sidebar/news.svg';
import SmsIcon from '../../../../assets/img/sidebar/sms.svg';
import FinanceIcon from '../../../../assets/img/sidebar/finance.svg';
import StatsIcon from '../../../../assets/img/sidebar/stats.svg';
import EmailIcon from '../../../../assets/img/sidebar/email.svg';
import PhonelistIcon from '../../../../assets/img/sidebar/phonelist.svg';

const SidebarManager = ({ expanded, setExpanded }) => {
  const { t } = useContext(TranslationsContext);
  const { can } = useContext(PermissionsContext);
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext);
  const [viberForm, setViberForm] = useState(null);

  useEffect(() => {
    if (expanded === 'panel2') {
      setTimeout(() => !viberForm && setViberForm({
        status: 'new',
        perPage: 1,
        page: 1,
        orderBy: 'id',
      }), 2000);
    }
  }, [expanded, viberForm]);

  return (
    <Accordion expanded={expanded === 'panel2'} onChange={() => setExpanded('panel2')}>
      <AccordionSummary
        component={Link}
        to={`${configs.prefix || ''}/manage/user/`}
        sx={{ color: '#f93', borderLeftColor: '#f93' }}
      >
        {t('widget:menu:clients')}
      </AccordionSummary>
      <AccordionDetails>
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/user/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/user/`.length) === `${configs.prefix || ''}/manage/user/`}>
              <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
              <ListItemText primary={t('widget:menu:clients')} />
            </ListItemButton>
          </ListItem>
          {(can('manager:reports') || can('manager:reports(mode=parent)')) && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/report/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/report/`.length) === `${configs.prefix || ''}/manage/report/`}>
                <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:reports')} />
              </ListItemButton>
            </ListItem>
          )}
          {(can('manager:router') || can('manager:router[site=own]')) && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/router/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/router/`.length) === `${configs.prefix || ''}/manage/router/`}>
                <ListItemIcon><img src={NewsIcon} alt="news" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:router')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:gates') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/gate/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/gate/`.length) === `${configs.prefix || ''}/manage/gate/`}>
                <ListItemIcon><img src={NewsIcon} alt="news" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:gate')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:incoming') && [52].indexOf(Number(user.site)) === -1 && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/incoming/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/incoming/`.length) === `${configs.prefix || ''}/manage/incoming/`}>
                <ListItemIcon><img src={SmsIcon} alt="sms" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:incoming')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:alphaname') && (
            <ListItem disablePadding>
              {[52].indexOf(Number(user.site)) > -1 ? (
                <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/scarpel-alphaname/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/scarpel-alphaname/`.length) === `${configs.prefix || ''}/manage/scarpel-alphaname/`}>
                  <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                  <ListItemText primary={t('widget:menu:clients:alphaname')} />
                </ListItemButton>
              ) : (
                <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/alphaname/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/alphaname/`.length) === `${configs.prefix || ''}/manage/alphaname/`}>
                  <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                  <ListItemText primary={t('widget:menu:clients:alphaname')} />
                </ListItemButton>
              )}
            </ListItem>
          )}
          {can('manager:pages') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/page/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/page/`.length) === `${configs.prefix || ''}/manage/page/`}>
                <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:pages')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:menu') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/menu/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/menu/`.length) === `${configs.prefix || ''}/manage/menu/`}>
                <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:menu')} />
              </ListItemButton>
            </ListItem>
          )}
          {(can('manager:finance') || can('manager:finance:invoice')) && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/finance/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/finance/`.length) === `${configs.prefix || ''}/manage/finance/`}>
                <ListItemIcon><img src={FinanceIcon} alt="finance" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:finance')} />
              </ListItemButton>
            </ListItem>
          )}
          {(can('manager:stats:days') || can('manager:stats:operators') || can('manager:stats:customers')
            || can('manager:stats:sites') || can('manager:stats:gates') || can('manager:stats:viber')) && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/stats/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/stats/`.length) === `${configs.prefix || ''}/manage/stats/`}>
                <ListItemIcon><img src={StatsIcon} alt="stats" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:stats')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:analysis') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/analysis/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/analysis/`.length) === `${configs.prefix || ''}/manage/analysis/`}>
                <ListItemIcon><img src={StatsIcon} alt="stats" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:analysis')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:tools') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/tools/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/tools/`.length) === `${configs.prefix || ''}/manage/tools/`}>
                <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:utils')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:site') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/site/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/site/`.length) === `${configs.prefix || ''}/manage/site/`}>
                <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:manager:site')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:software') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/software/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/software/`.length) === `${configs.prefix || ''}/manage/software/`}>
                <ListItemIcon><img src={SmsIcon} alt="sms" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:software')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:newsletter') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/newsletter/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/newsletter/`.length) === `${configs.prefix || ''}/manage/newsletter/`}>
                <ListItemIcon><img src={EmailIcon} alt="email" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:newsletter')} />
              </ListItemButton>
            </ListItem>
          )}
          {(can('manager:support') || can('manager:support:view')) && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/support/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/support/`.length) === `${configs.prefix || ''}/manage/support/`}>
                <ListItemIcon><img src={EmailIcon} alt="email" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:support')} />
              </ListItemButton>
            </ListItem>
          )}
          {(can('manager:blacklist') || can('manager:whitelist')) && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/phonelist/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/phonelist/`.length) === `${configs.prefix || ''}/manage/phonelist/`}>
                <ListItemIcon><img width={24} src={PhonelistIcon} alt="phonelist" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:phonelist')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:news') && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/news/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/news/`.length) === `${configs.prefix || ''}/manage/news/`}>
                <ListItemIcon><img src={NewsIcon} alt="news" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:news')} />
              </ListItemButton>
            </ListItem>
          )}
          {(can('manager:notification') || can('manager:translation')) && (
            <ListItem disablePadding>
              <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/translations/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/translations/`.length) === `${configs.prefix || ''}/manage/translations/`}>
                <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                <ListItemText primary={t('widget:menu:clients:translations')} />
              </ListItemButton>
            </ListItem>
          )}
          {can('manager:templates') && (
            <ViberTemplatesProvider form={viberForm}>
              <ViberTemplatesConsumer>
                {({ data }) => (
                  <ListItem disablePadding>
                    <ListItemButton component={Link} to={`${configs.prefix || ''}/manage/templates/`} selected={pathname.slice(0, `${configs.prefix || ''}/manage/templates/`.length) === `${configs.prefix || ''}/manage/templates/`}>
                      <ListItemIcon><img src={DocIcon} alt="doc" /></ListItemIcon>
                      <ListItemText
                        primary={(
                          <Badge badgeContent={data && data.total ? data.total : 0} color="primary">
                            {t('widget:menu:clients:templates')}
                          </Badge>
                        )}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
              </ViberTemplatesConsumer>
            </ViberTemplatesProvider>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default SidebarManager;
