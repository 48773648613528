import React from 'react';
import PropTypes from 'prop-types';

import DataProvider from '../../HO/State/DataProvider';
import { Provider } from './ConfigsContext';

const ConfigsProvider = ({ children, form }) => (
  <DataProvider route="configs" Provider={Provider} form={form}>
    {children}
  </DataProvider>
);

ConfigsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.instanceOf(Object),
};

ConfigsProvider.defaultProps = {
  form: null,
};

export default ConfigsProvider;
