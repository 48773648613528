import React from 'react';
import PropTypes from 'prop-types';

import DataProvider from '../../HO/State/DataProvider';
import { Provider } from './TranslationsContext';

const TranslationsProvider = ({ children, form }) => (
  <DataProvider
    route="translations"
    Provider={Provider}
    form={form}
    value={{
      t: (k, data) => (data || {})[k],
      lang: form.language,
    }}
  >
    {children}
  </DataProvider>
);

TranslationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  form: PropTypes.instanceOf(Object),
};

TranslationsProvider.defaultProps = {
  form: null,
};

export default TranslationsProvider;
