import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Provider } from './AuthContext';
import configs from '../../../configs';

const AuthProvider = ({
  children,
  setShowLoading,
  sendRequest,
  form,
}) => {
  const authTokenName = configs.token;
  const userAuthTokenKey = `user_${authTokenName}`;

  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(localStorage.getItem(authTokenName));
  const [userAuthToken, setUserAuthToken] = useState(localStorage.getItem(userAuthTokenKey));

  const checkAuth = () => {
    // if (authToken) {
    setShowLoading(true);
    // localStorage.setItem(authTokenName, authToken);

    sendRequest('auth', { body: form }).then((response) => {
      if (response.success && response.data) {
        setUser(response.data);
        // localStorage.setItem('uid', response.user.id);
      } else {
        // localStorage.removeItem(authTokenName);
        // localStorage.removeItem(userAuthTokenKey);

        if (user) {
          setUser(null);
        }
        setAuthToken(null);
      }
      setShowLoading(false);
    });
    // } else {
    //   localStorage.removeItem(authTokenName);
    //   localStorage.removeItem(userAuthTokenKey);
    //
    //   if (user) {
    //     setUser(null);
    //   }
    //   setShowLoading(false);
    // }
  };

  const userEnter = (newAuthToken) => {
    const newUserAuthToken = localStorage.getItem(authTokenName);
    localStorage.setItem(authTokenName, newAuthToken);
    localStorage.setItem(userAuthTokenKey, newUserAuthToken);

    setAuthToken(newAuthToken);
    setUserAuthToken(newUserAuthToken);
  };

  const backToUser = () => {
    const newAuthToken = localStorage.getItem(userAuthTokenKey);

    if (newAuthToken) {
      localStorage.setItem(authTokenName, newAuthToken);
      localStorage.removeItem(userAuthTokenKey);

      setAuthToken(newAuthToken);
      setUserAuthToken(null);
    }
  };

  const getUser = () => {
    sendRequest('auth', {}).then((response) => {
      if (response.success && response.user) {
        setUser(response.user);
      }
    });
  };

  useEffect(checkAuth, [authToken]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Provider
      value={{
        user,
        userAuthToken,
        setUser,
        getUser,
        setAuthToken,
        backToUser,
        userEnter,
      }}
    >
      {children}
    </Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
  setShowLoading: PropTypes.func.isRequired,
  sendRequest: PropTypes.func.isRequired,
  form: PropTypes.instanceOf(Object),
};

AuthProvider.defaultProps = {
  form: {},
};

export default AuthProvider;
