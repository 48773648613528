import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Provider } from './StateContext';
import routes from '../../../routes/routes';

const reactControllers = [];

const StateProvider = (props) => {
  const { children } = props;

  const [showLoading, setShowLoading] = useState(true);

  const jobResultTimer = useRef(null);

  const sendRequest = (route, options) => {
    const opts = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      // mimeType: 'multipart/form-data',
      credentials: 'include',
      ...(options || {}),
    };

    if (window.AccessToken) {
      opts.headers['Access-Token'] = window.AccessToken;
    }

    if (opts.body) {
      if (opts.headers['Content-Type'] === 'application/json') {
        opts.body = JSON.stringify(opts.body);
      } else if (typeof opts.body === 'object' && opts.headers.type !== 'formData') {
        opts.body = new URLSearchParams(opts.body);
      }
      if (opts.headers['Content-Type'] === 'multipart/form-data') {
        delete opts.headers['Content-Type'];
        delete opts.headers['content-type'];
      }
    }

    if (route in routes) {
      if (route in reactControllers) {
        reactControllers[route].abort();
      }

      reactControllers[route] = new AbortController();
      opts.signal = reactControllers[route].signal;

      return fetch(routes[route], opts).then((response) => {
        if (route in reactControllers) {
          delete reactControllers[route];
        }

        if (!response.ok) {
          return {
            errors: {
              route: `Route "${route}" no ok`,
            },
          };
        }

        // const version = response.headers.get('X-Version');
        // const balance = response.headers.get('X-Balance'); TODO need after remove angular
        // const credit = response.headers.get('X-Credit');

        // if (version) {
        //   window.localStorage.setItem('ls.version', version);
        // }

        if (response.url.includes('/arrayToFile/')
          || response.url.includes('/export/')
          || response.url.includes('/balanceHistoryExport/')
          || response.url.includes('/viberTemplatesExport/')
          || response.url.includes('/download/')
          || response.url.includes('/exportPhones/')) {
          return response.blob();
        }

        return response.text().then((text) => {
          try {
            return JSON.parse(text);
          } catch (e) {
            // console.log(text);
            return text;
          }
        });
      }).catch((error) => {
        if (route in reactControllers) {
          delete reactControllers[route];
        }

        return {
          errors: {
            route: error,
          },
        };
      });
    }

    // console.error(`Route "${route}" does not exists in routes config`);

    return Promise.resolve({
      errors: {
        route: `Route "${route}" does not exists in routes config`,
      },
    });
  };

  const abortRequest = (route) => {
    if (route in reactControllers) {
      reactControllers[route].abort();
    }
  };

  const jobResult = (job, callbackSuccess, callbackProcess, callbackError) => {
    clearTimeout(jobResultTimer.current);

    sendRequest('jobResult', {
      body: {
        job,
      },
    }).then((response) => {
      if (response && response.success) {
        if (Number(response.data.status_id) === 4) { // success
          if (typeof callbackSuccess === 'function') {
            callbackSuccess(response.data.result);
          }
        } else if (Number(response.data.status_id) === 3) { // error
          if (typeof callbackError === 'function') {
            callbackError();
          }
        } else { // other
          if (Number(response.data.status_id) === 2 && typeof callbackProcess === 'function') { // in process
            callbackProcess(response.data.percent, response.data.status_text || '');
          }

          jobResultTimer.current = setTimeout(
            jobResult,
            2000,
            job,
            callbackSuccess,
            callbackProcess,
            callbackError,
          );
        }
      }
    });
  };

  const currentProps = {
    value: {
      showLoading,
      setShowLoading,
      sendRequest,
      abortRequest,
      jobResult,
    },
  };

  return (
    <Provider {...currentProps}>{children}</Provider>
  );
};

StateProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StateProvider;
